import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Mail from '@material-ui/icons/Mail';
import VpnKey from '@material-ui/icons/VpnKey';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import OrganisationEditDialog from './OrganisationEditDialog';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class OrganisationDetails extends Component {
    constructor(props) {
        super(props);
        this.editOrganisationDialog = React.createRef();
    }
    transformStatusToText = statusNr => {
        const STATUS_INDICATORS = [
            'Prospect',
            'Trial',
            'Client',
            'Opgeschort',
            'Geen interesse',
        ];
        return STATUS_INDICATORS[statusNr];
    };

    transformSiteIdToManagerName = siteId => {
        const managers = this.props.managers;
        const filteredManagers = managers.filter(
            manager => manager.siteId.indexOf(siteId) > -1,
        );
        if (filteredManagers[0]) return filteredManagers[0].name;
        return null;
    };

    transformSiteIdToSiteName = siteId => {
        const SITE_NAMES = [
            'dnls.nl',
            'dnls.be BE',
            'dnls.be FR',
            'locationagent.de',
            'topvenues-london.co.uk',
            'wegmetdebaas.nl',
            'wegmetdebaas.be BE',
            'wegmetdebaas.be FR',
            'wegmitdemchef.de',
            'wegmetdekids.nl',
            'eventspot.nl',
        ];
        return SITE_NAMES[siteId - 1];
    };

    openEditOrganisationDialog = () => {
        this.editOrganisationDialog.current.open();
    };

    handleEditOrganisation = (status, addres) => {
        this.props.handleEdit(this.props.organisation, {
            status: status,
            addres: addres,
        });
    };

    render() {
        const { classes, organisation } = this.props;

        return (
            <div>
                <OrganisationEditDialog
                    innerRef={this.editOrganisationDialog}
                    organisation={organisation}
                    handleSave={this.handleEditOrganisation}
                />
                <Paper className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item xs={9}>
                            <Typography variant="subheading">
                                Organisatie details
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip
                                title="Wijzig organisatie details"
                                placement="top">
                                <IconButton
                                    onClick={this.openEditOrganisationDialog}
                                    aria-label="Wijzig organisatie details">
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${
                                    process.env.REACT_APP_FRONT_URL
                                }/admin/sendemails?site_id=${
                                    organisation.site_id
                                }&receiver=${
                                    organisation.service_email
                                }&statistics=1`}>
                                <Tooltip
                                    title="Verstuur service email"
                                    placement="top">
                                    <IconButton aria-label="Verstuur een service email">
                                        <Mail />
                                    </IconButton>
                                </Tooltip>
                            </a>
                        </Grid>
                        <Grid item xs={1}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${
                                    process.env.REACT_APP_FRONT_URL
                                }/admin/login_advertiser?org_id=${
                                    organisation.id
                                }`}>
                                <Tooltip
                                    title="Login als adverteerder"
                                    placement="top">
                                    <IconButton aria-label="Login als een adverteerder">
                                        <VpnKey />
                                    </IconButton>
                                </Tooltip>
                            </a>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start">
                        {organisation.created_at && (
                            <Grid container alignItems="center">
                                <Grid item xs={3}>
                                    <Typography variant="body2" noWrap>
                                        Aangemaakt:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        noWrap>
                                        {organisation.created_at}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        {organisation.site_id && (
                            <Grid container alignItems="center">
                                <Grid item xs={3}>
                                    <Typography variant="body2" noWrap>
                                        Manager:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography
                                        variant="body1"
                                        noWrap
                                        color="textSecondary">
                                        {this.transformSiteIdToManagerName(
                                            organisation.site_id,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        {organisation.status >= 0 &&
                            organisation.status <= 4 && (
                                <Grid container alignItems="center">
                                    <Grid item xs={3}>
                                        <Typography variant="body2" noWrap>
                                            Status:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="body1" noWrap>
                                            {this.transformStatusToText(
                                                organisation.status,
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                        {organisation.site_id >= 1 &&
                            organisation.site_id <= 11 && (
                                <Grid container alignItems="center">
                                    <Grid item xs={3}>
                                        <Typography variant="body2" noWrap>
                                            Site:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            noWrap>
                                            {this.transformSiteIdToSiteName(
                                                organisation.site_id,
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                        <Grid container alignItems="center">
                            <Grid item xs={3}>
                                <Typography variant="body2" noWrap>
                                    Adres:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="body1" noWrap>
                                    {organisation.address
                                        ? organisation.address
                                        : 'Niet ingevuld'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

OrganisationDetails.propTypes = {
    organisation: PropTypes.object.isRequired,
    managers: PropTypes.array.isRequired,
    handleEdit: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(OrganisationDetails);
