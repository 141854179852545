import APICall from '../helpers/ApiCall';
import { notify } from './notification.action';

/**
 * Get's all the calendar items for the selected managers
 * @param {Array} managers Array with manager ids
 */
export function getCalendarItems(
    managers,
    startDate,
    pageStart,
    callback = () => {},
) {
    return dispatch => {
        if (Array.isArray(managers) === false) return;

        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const offset = pageStart ? pageStart * 16 : 0;

        APICall.get(
            `${
                process.env.REACT_APP_API_URL
            }/manager/${managers.join()}/calendar?startDate=${formattedStartDate}&offset=${offset}`,
        ).then(calendar => {
            callback(calendar);
            return dispatch(setCalendarItems(calendar, pageStart));
        });
    };
}

export const DELETE_CALENDAR_ITEM = 'DELETE_CALENDAR_ITEM';
export const DELETE_CALENDAR_ITEM_ORGANISATION =
    'DELETE_CALENDAR_ITEM_ORGANISATION';
export function deleteCalendarItem(itemId) {
    return dispatch => {
        APICall.delete(
            `${process.env.REACT_APP_API_URL}/calendar/${itemId}`,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message:
                            'Het is niet gelukt om het agenda item te verwijderen',
                    }),
                );
            }

            dispatch(
                notify({
                    level: 'success',
                    message: 'Agenda item succesvol verwijderd',
                }),
            );
            dispatch({
                type: DELETE_CALENDAR_ITEM_ORGANISATION,
                itemId: itemId,
            });
            return dispatch({
                type: DELETE_CALENDAR_ITEM,
                itemId: itemId,
            });
        });
    };
}

export const EDIT_CALENDAR_ITEM = 'EDIT_CALENDAR_ITEM';
export const EDIT_CALENDAR_ORGANISATION = 'EDIT_CALENDAR_ORGANISATION';
export function editCalendarItem(itemId, oldValues, newValues) {
    return dispatch => {
        const body = { ...oldValues, ...newValues };
        APICall.put(
            `${process.env.REACT_APP_API_URL}/calendar/${itemId}`,
            body,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message:
                            'Het is niet gelukt om het agenda item te wijzigen',
                    }),
                );
            }

            dispatch(
                notify({
                    level: 'success',
                    message: 'Agenda item succesvol gewijzigd',
                }),
            );

            dispatch({
                type: EDIT_CALENDAR_ORGANISATION,
                item: result[0],
            });
            return dispatch({
                type: EDIT_CALENDAR_ITEM,
                item: result[0],
            });
        });
    };
}

export const ADD_CALENDAR_ITEM_ORGANISATION = 'ADD_CALENDAR_ITEM_ORGANISATION';
export function addCalendarItem(calendarItem) {
    return dispatch => {
        APICall.post(
            `${process.env.REACT_APP_API_URL}/calendar`,
            calendarItem,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message:
                            'Het is niet gelukt om het agenda item aan te maken',
                    }),
                );
            }

            dispatch(
                notify({
                    level: 'success',
                    message: 'Agenda item succesvol toegevoegd',
                }),
            );

            return dispatch({
                type: ADD_CALENDAR_ITEM_ORGANISATION,
                item: result[0],
            });
        });
    };
}

export const SET_CALENDAR = 'SET_CALENDAR';
export const PUSH_CALENDAR = 'PUSH_CALENDAR';
export function setCalendarItems(calendar, pageStart) {
    return {
        type: pageStart === 0 ? SET_CALENDAR : PUSH_CALENDAR,
        calendar: calendar,
    };
}
