export const NOTIFY = 'NOTIFY';
export function notify(notification) {
    return {
        type: NOTIFY,
        notification: {
            level: notification.level,
            message: notification.message,
            open: true,
        },
    };
}

export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export function closeNotification(id) {
    return {
        type: CLOSE_NOTIFICATION,
        id: id,
    };
}
