import React, { Component } from 'react';
import ErrorPage from './components/ErrorPage';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false,
            error: null
        };
    }
    
    componentDidCatch(error, info) {
        debugger;
        this.setState({ 
            hasError: true,
            error: error
        });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage error={this.state.error}></ErrorPage>
        }
        return this.props.children; 
    }
}