import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import SearchContainer from '../containers/SearchContainer';
import { history } from '../routes/Routes';

class Header extends Component {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleAgenda = () => {
        history.push('/crm/calendar');
        this.handleClose();
    };

    handleAccounts = () => {
        history.push('/crm/accounts');
        this.handleClose();
    };

    handleLogout = () => {
        this.props.handleLogout();
        this.handleClose();
    };

    render() {
        const { manager } = this.props;
        const { anchorEl } = this.state;

        return (
            <AppBar position="sticky" color="primary">
                <Toolbar>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center">
                        <Grid item xs zeroMinWidth>
                            <Typography
                                variant="title"
                                color="inherit"
                                onClick={this.handleAgenda}
                                noWrap>
                                Web-Effects CRM Systeem
                            </Typography>
                        </Grid>
                        <Grid item xs align="center">
                            <SearchContainer />
                        </Grid>
                        <Grid item xs align="right">
                            <Button
                                color="inherit"
                                aria-owns={anchorEl ? 'simple-menu' : null}
                                aria-haspopup="true"
                                onClick={this.handleClick}>
                                {manager.name || '...'}
                            </Button>

                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}>
                                <MenuItem onClick={this.handleAgenda}>
                                    Agenda
                                </MenuItem>
                                <MenuItem onClick={this.handleAccounts}>
                                    Accounts
                                </MenuItem>
                                <MenuItem onClick={this.handleLogout}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }
}

Header.propTypes = {
    manager: PropTypes.object.isRequired,
    handleLogout: PropTypes.func.isRequired,
};

export default Header;
