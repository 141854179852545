import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
    loader: {
        margin: theme.spacing.unit * 2,
        display: 'block',
        align: 'center',
    },
});

const Loader = ({ classes, loading }) => {
    return (
        loading && (
            <Grid container justify="center" key={0}>
                <Grid item>
                    <CircularProgress className={classes.loader} />
                </Grid>
            </Grid>
        )
    );
};

Loader.propTypes = {
    loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Loader);
