import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const styles = theme => ({
    filtersContainer: {
        margin: `${theme.spacing.unit * 2}px 0 0 0`,
    },
});

class EditContactDialog extends Component {
    state = {
        open: false,
        checkedAdvertiser: false,
        checkedServiceEmail: false,
        name: '',
        email: '',
        phone: '',
    };

    componentWillReceiveProps() {
        if (this.props.contact) {
            const { contact, organisation } = this.props;

            if (contact.name) this.setState({ name: contact.name });
            if (contact.email) this.setState({ email: contact.email });
            if (contact.phone) this.setState({ phone: contact.phone });
            if (contact.status === 1)
                this.setState({ checkedAdvertiser: true });
            if (contact.status === 0)
                this.setState({ checkedAdvertiser: false });

            if (organisation.service_email_cc.includes(contact.email))
                this.setState({ checkedServiceEmail: true });
            if (!organisation.service_email_cc.includes(contact.email))
                this.setState({ checkedServiceEmail: false });
        }
    }

    open = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.reset();
    };

    handleSave = () => {
        this.handleClose();
        this.props.handleSave(this.props.contact, {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            status: this.state.checkedAdvertiser,
            serviceEmail: this.state.checkedServiceEmail,
            organisation_id: this.props.organisation.id,
        });
        this.reset();
    };

    reset = () => {
        this.setState({ name: '' });
        this.setState({ email: '' });
        this.setState({ phone: '' });
        this.setState({ checkedAdvertiser: false });
        this.setState({ checkedServiceEmail: false });
    };

    handleChangeCheckbox = identifier => event => {
        this.setState({ [identifier]: event.target.checked });
    };

    handleChangeTextField = identifier => event => {
        this.setState({ [identifier]: event.target.value });
    };

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {this.props.isNew ? (
                        <span>Voeg een nieuw contact persoon toe</span>
                    ) : (
                        <span>Wijzig dit contact persoon</span>
                    )}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {this.props.isNew ? (
                            <span>
                                Vul de onderliggende velden in en druk
                                vervolgens op opslaan om dit contact toe te
                                voegen.
                            </span>
                        ) : (
                            <span>
                                Verander de content in de onderliggende velden
                                en druk vervolgens op opslaan, om dit contact op
                                te slaan.
                            </span>
                        )}
                    </DialogContentText>

                    <Grid
                        container
                        spacing={8}
                        justify="space-between"
                        alignItems="flex-end">
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Naam"
                                onChange={this.handleChangeTextField('name')}
                                defaultValue={this.state.name}
                            />
                        </Grid>
                        <Grid item sm={6} x={12}>
                            <TextField
                                fullWidth
                                label="Telefoonnummer"
                                onChange={this.handleChangeTextField('phone')}
                                defaultValue={this.state.phone}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="E-mail adres"
                                onChange={this.handleChangeTextField('email')}
                                defaultValue={this.state.email}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.checkedAdvertiser}
                                        onChange={this.handleChangeCheckbox(
                                            'checkedAdvertiser',
                                        )}
                                        color="primary"
                                    />
                                }
                                label="Contact is adverteerder"
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.checkedServiceEmail}
                                        onChange={this.handleChangeCheckbox(
                                            'checkedServiceEmail',
                                        )}
                                        color="primary"
                                    />
                                }
                                label="Contact ontvang een service email"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="inherit" onClick={this.handleClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.handleSave}>
                        Opslaan
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditContactDialog.propTypes = {
    organisation: PropTypes.object.isRequired,
    handleSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(EditContactDialog);
