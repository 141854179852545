import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ErrorPage = ({ error }) => {
    return (
        <Fragment>
            <h1>Something went wrong.</h1>
            <p>
                <strong>Error: </strong>
                {error}
            </p>
        </Fragment>
    );
};

ErrorPage.propTypes = {
    error: PropTypes.object.isRequired,
};

export default ErrorPage;
