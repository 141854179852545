import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SelectManagers from './FilterComponents/SelectManagers';
import PropTypes from 'prop-types';
import DateFilter from './FilterComponents/DateFilter';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import moment from 'moment';

const styles = theme => ({
    filtersContainer: {
        margin: `${theme.spacing.unit * 2}px 0 0 0`,
    },
});

class EditEventDialog extends Component {
    state = {
        open: false,
        editorState: EditorState.createEmpty(),
        selectedManagers: [],
        targetDate: new moment(),
    };

    reset = () => {
        this.setState({ editorState: EditorState.createEmpty() });
        this.setState({ selectedManagers: [] });
        this.setState({ targetDate: new moment() });
    };

    componentWillReceiveProps() {
        if (this.props.loggedInManager)
            this.setState({
                selectedManagers: [this.props.loggedInManager.id],
            });

        if (this.props.event) {
            try {
                this.setState({
                    editorState: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML(this.props.event.message),
                        ),
                    ),
                });
            } catch (e) {
                console.warn(
                    `Could not convert HTML for calendar item ${
                        this.props.event.id
                    }`,
                );
            }

            this.setState({
                selectedManagers: [this.props.event.managerId],
            });
            this.setState({
                targetDate: this.props.event.targetDate,
            });
        }
    }

    open = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.reset();
        this.setState({ open: false });
    };

    handleSave = () => {
        let options = {
            defaultBlockTag: 'div',
        };
        this.props.handleSave(
            this.state.selectedManagers,
            this.state.targetDate,
            stateToHTML(this.state.editorState.getCurrentContent(), options),
        );
        this.handleClose();
    };

    handleEditorChange = editorState => {
        this.setState({ editorState: editorState });
    };

    handleSelectedManagersChange = value => {
        this.setState({
            selectedManagers: value.selectedManagers,
        });
    };

    handleDateChanged = value => {
        this.setState({
            targetDate: value.selectedDate.add(2, 'hours'),
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {this.props.isNew ? (
                        <span>Voeg een nieuw item toe</span>
                    ) : (
                        <span>Wijzig dit item</span>
                    )}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {this.props.isNew ? (
                            <span>
                                Verander de content in de onderliggende velden
                                en druk vervolgens op opslaan, om dit item toe
                                te voegen.
                            </span>
                        ) : (
                            <span>
                                Verander de content in de onderliggende velden
                                en druk vervolgens op opslaan, om dit item te
                                wijzigen.
                            </span>
                        )}
                    </DialogContentText>

                    <Grid
                        container
                        className={classes.filtersContainer}
                        spacing={8}
                        justify="space-between"
                        alignItems="flex-end">
                        <Grid item sm={6} xs={12}>
                            <SelectManagers
                                innerRef={this.selectManagersInput}
                                fullWidth
                                multiple={false}
                                defaultValue={this.state.selectedManagers}
                                handleFilterChanged={
                                    this.handleSelectedManagersChange
                                }
                            />
                        </Grid>

                        {((this.props.event && this.props.event.targetDate) ||
                            this.props.isNew) &&
                            !this.props.isNote && (
                                <Grid item sm={6} xs={12}>
                                    <DateFilter
                                        label="Target datum"
                                        handleFilterChanged={
                                            this.handleDateChanged
                                        }
                                        defaultValue={this.state.targetDate}
                                    />
                                </Grid>
                            )}

                        <Grid item xs={12}>
                            <Editor
                                toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                }}
                                editorState={this.state.editorState}
                                onEditorStateChange={this.handleEditorChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="inherit" onClick={this.handleClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.handleSave}>
                        Opslaan
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditEventDialog.propTypes = {
    handleSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(EditEventDialog);
