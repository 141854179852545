import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Loader from '../components/Loader';
import { getCalendarItems } from '../actions/calendar.action';
import AlertPaper from '../components/AlertPaper';
import { parseCalendar } from '../helpers/parseCalendar';

const mapStateToProps = state => {
    return {
        calendar: state.calendar,
        filters: state.filters,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCalendar: (managerIds, startDate, pageToLoad, callback) => {
            dispatch(
                getCalendarItems(managerIds, startDate, pageToLoad, callback),
            );
        },
    };
};

class InfiniteScrollContainer extends Component {
    state = {
        isLoading: false,
        hasMore: true,
        pageStart: 0,
    };

    componentDidUpdate(prevProps) {
        if (
            (prevProps.calendar.length === 0 &&
                this.props.calendar.length > 0) ||
            (prevProps.filters.selectedManagers.length === 0 &&
                this.props.filters.selectedManagers.length > 0)
        ) {
            this.loadItems();
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const { isLoading, hasMore } = this.state;

        // Bails early if:
        // * there's an error
        // * it's already loading
        // * there's nothing left to load
        if (isLoading || !hasMore) return;

        // Checks that the page has scrolled to the bottom
        if (
            window.innerHeight + document.documentElement.scrollTop >
            document.documentElement.offsetHeight -
                (this.props.treshhold || 100)
        ) {
            this.setState({ pageStart: this.state.pageStart + 1 }, () => {
                this.loadItems();
            });
        }
    };

    loadItems = () => {
        this.setState({ isLoading: true }, () => {
            const selectedManagers = this.props.filters.selectedManagers;
            const selectedDate = this.props.filters.selectedDate;

            this.props.getCalendar(
                selectedManagers,
                selectedDate,
                this.state.pageStart,
                result => {
                    this.setState({
                        isLoading: false,
                        hasMore: result.length >= 16,
                    });
                },
            );
        });
    };

    render() {
        let calendar = this.props.calendar;
        calendar = parseCalendar(calendar);

        return (
            <Fragment>
                {calendar.length === 0 && this.state.isLoading === false ? (
                    <AlertPaper>
                        Er zijn momeel geen items op je kalender. Probeer een
                        andere filter configuratie te selecteren.
                    </AlertPaper>
                ) : (
                    calendar.map((item, key) => {
                        return (
                            <Fade
                                key={`${item.id}-${key}`}
                                in={true}
                                timeout={200 * ((key % 16) + 1)}>
                                {item.title ? (
                                    <Typography variant="subheading">
                                        {item.title}
                                    </Typography>
                                ) : (
                                    <div>
                                        {React.cloneElement(
                                            this.props.children,
                                            { item: item },
                                        )}
                                    </div>
                                )}
                            </Fade>
                        );
                    })
                )}

                <Loader loading={this.state.isLoading} />
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InfiniteScrollContainer);
