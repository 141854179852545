import React, { Component } from 'react';
import {
    Grid,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Input,
    Button,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { notify } from '../actions/notification.action';
import { submitNewPassord } from '../actions/manager.action';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit *
            3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

const mapDispatchToProps = dispatch => {
    return {
        notify: (level, message) => {
            dispatch(notify({ level: level, message: message }));
        },
        resetPassword: (token, password) => {
            dispatch(submitNewPassord(token, password));
        },
    };
};

class ResetPasswordContainer extends Component {
    handleSubmit = event => {
        event.preventDefault();
        const password = event.target.password.value;
        const password1 = event.target.password1.value;
        const token = this.props.match.params.token;

        if (password !== password1)
            return this.props.notify('error', 'Wachtwoorden zijn niet gelijk');

        return this.props.resetPassword(token, password);
    };

    render() {
        const { classes } = this.props;

        return (
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <Paper className={classes.paper}>
                        <Typography variant="headline">
                            Web-Effects CRM - Reset password
                        </Typography>
                        <p>
                            Reset your password by submitting a new password in
                            the form below.
                        </p>

                        <form
                            onSubmit={this.handleSubmit}
                            className={classes.form}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">
                                    New password
                                </InputLabel>
                                <Input
                                    id="password"
                                    type="password"
                                    name="password"
                                    autoFocus
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">
                                    Confirm new password
                                </InputLabel>
                                <Input
                                    id="password1"
                                    type="password"
                                    name="password1"
                                />
                            </FormControl>

                            <Button
                                type="submit"
                                fullWidth
                                variant="raised"
                                color="primary"
                                className={classes.submit}>
                                Reset password
                            </Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(ResetPasswordContainer));
