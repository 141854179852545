class Fetcher {
    /**
     * Make a fetch request
     * @param {String} url
     * @param {Object} request
     * @returns {Promise} Returns a fetch promise
     */
    static generateRequest(url, request) {
        return fetch(url, request)
            .then(response => response.json())
            .then(result => {
                return result;
            })
            .catch(error => {
                console.error(error);
            });
    }
}

export default Fetcher;
