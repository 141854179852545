import React, { Component } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    TextField,
    DialogTitle,
    DialogContentText,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({});

class EditManagerDialog extends Component {
    state = {
        open: false,
        name: '',
        email: '',
        siteId: [],
    };

    open = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSave = () => {
        const name = this.state.name;
        const email = this.state.email;
        const siteId = this.state.siteId;

        this.props.handleSave(name, email, siteId);
        this.handleClose();
    };

    handleChangeName = event => {
        this.setState({ name: event.target.value });
    };

    handleChangeEmail = event => {
        this.setState({ email: event.target.value });
    };

    handleSiteIdChange = event => {
        this.setState({ siteId: event.target.value });
    };

    static getDerivedStateFromProps(props, current_state) {
        if (props.manager && current_state.name === '') {
            return { name: props.manager.name };
        }
        if (props.manager && current_state.email === '') {
            return { email: props.manager.email };
        }
        if (props.manager && current_state.siteId === '') {
            return { siteId: props.manager.siteId };
        }
        return current_state;
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>
                    {this.props.isNew ? (
                        <span>Voeg een manager toe</span>
                    ) : (
                        <span>Wijzig een manager</span>
                    )}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.isNew ? (
                            <span>
                                Verander de content in de onderliggende velden
                                en druk vervolgens op opslaan, om dit item toe
                                te voegen.
                            </span>
                        ) : (
                            <span>
                                Verander de content in de onderliggende velden
                                en druk vervolgens op opslaan, om dit item op te
                                slaan.
                            </span>
                        )}
                    </DialogContentText>

                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <TextField
                                label="Naam"
                                type="text"
                                fullWidth
                                onChange={this.handleChangeName}
                                defaultValue={this.state.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                type="email"
                                fullWidth
                                onChange={this.handleChangeEmail}
                                defaultValue={this.state.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    Site verantwoordelijk voor
                                </InputLabel>
                                <Select
                                    value={this.state.siteId}
                                    onChange={this.handleSiteIdChange}
                                    multiple={true}>
                                    <MenuItem value={1}>DNLS.nl</MenuItem>
                                    <MenuItem value={2}>DNLS.be NL</MenuItem>
                                    <MenuItem value={3}>DNLS.be FR</MenuItem>
                                    <MenuItem value={4}>
                                        LocationAgent.de
                                    </MenuItem>
                                    <MenuItem value={5}>
                                        Topvenues-london.co.uk
                                    </MenuItem>
                                    <MenuItem value={6}>
                                        Wegmetdebaas.nl
                                    </MenuItem>
                                    <MenuItem value={7}>
                                        Wegmetdebaas.be NL
                                    </MenuItem>
                                    <MenuItem value={8}>
                                        Wegmetdebaas.be FR
                                    </MenuItem>
                                    <MenuItem value={9}>
                                        Wegmitdemchef.de
                                    </MenuItem>
                                    <MenuItem value={10}>
                                        Wegmetdekids.nl
                                    </MenuItem>
                                    <MenuItem value={11}>Eventspot.nl</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={this.handleClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.handleSave}>
                        Opslaan
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditManagerDialog.propTypes = {
    handleSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(EditManagerDialog);
