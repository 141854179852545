import APICall from '../helpers/ApiCall';
import { notify } from './notification.action';

export const DELETE_NOTE_ITEM_ORGANISATION = 'DELETE_NOTE_ITEM_ORGANISATION';
export function deleteNoteItem(itemId) {
    return dispatch => {
        APICall.delete(`${process.env.REACT_APP_API_URL}/note/${itemId}`).then(
            result => {
                if (result.error) {
                    return dispatch(
                        notify({
                            level: 'error',
                            message:
                                'Het is niet gelukt om de notitie te verwijderen',
                        }),
                    );
                }

                dispatch(
                    notify({
                        level: 'success',
                        message: 'Notitie succesvol verwijderd',
                    }),
                );
                return dispatch({
                    type: DELETE_NOTE_ITEM_ORGANISATION,
                    itemId: itemId,
                });
            },
        );
    };
}

export const EDIT_NOTE_ITEM_ORGANISATION = 'EDIT_NOTE_ITEM_ORGANISATION';
export function editNoteItem(itemId, oldValues, newValues) {
    return dispatch => {
        const body = { ...oldValues, ...newValues };
        APICall.put(
            `${process.env.REACT_APP_API_URL}/note/${itemId}`,
            body,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message: 'Het is niet gelukt om de notitie te wijzigen',
                    }),
                );
            }

            dispatch(
                notify({
                    level: 'success',
                    message: 'Notitie succesvol gewijzigd',
                }),
            );
            return dispatch({
                type: EDIT_NOTE_ITEM_ORGANISATION,
                item: result[0],
            });
        });
    };
}

export const ADD_NOTE_ITEM_ORGANISATION = 'ADD_NOTE_ITEM_ORGANISATION';
export function addNoteItem(noteItem) {
    return dispatch => {
        APICall.post(`${process.env.REACT_APP_API_URL}/note`, noteItem).then(
            result => {
                if (result.error) {
                    return dispatch(
                        notify({
                            level: 'error',
                            message:
                                'Het is niet gelukt om het notitie aan te maken',
                        }),
                    );
                }

                dispatch(
                    notify({
                        level: 'success',
                        message: 'Notitie succesvol toegevoegd',
                    }),
                );

                return dispatch({
                    type: ADD_NOTE_ITEM_ORGANISATION,
                    item: result[0],
                });
            },
        );
    };
}
