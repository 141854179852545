import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import JWTToken from '../helpers/jwtToken';

function isLoggedIn() {
    return JWTToken.checkTokenIsExpired('refreshToken') === false;
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isLoggedIn() ? <Component {...props} /> : <Redirect to="/" />
        }
    />
);
