import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

class OrganisationEditDialog extends Component {
    state = {
        open: false,
        address: '',
        status: '',
    };

    open = () => {
        this.setState({ open: true });

        if (this.props.organisation) {
            this.setState({ address: this.props.organisation.address });
        }

        if (this.props.organisation) {
            this.setState({ status: this.props.organisation.status });
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleStatusChange = event => {
        this.setState({ status: event.target.value });
    };

    handleAdresChange = event => {
        this.setState({ address: event.target.value });
    };

    handleSave = () => {
        const status = this.state.status;
        const address = this.state.address;

        this.handleClose();
        this.props.handleSave(status, address);
    };

    render() {
        const { organisation } = this.props;

        return (
            <Dialog open={this.state.open} onClose={this.handleClose}>
                <DialogTitle>Wijzig {organisation.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wijzig onderstaande gegevens om deze organisatie te
                        wijzigen
                    </DialogContentText>

                    <FormControl fullWidth>
                        <InputLabel>Organisation status</InputLabel>
                        <Select
                            value={this.state.status}
                            onChange={this.handleStatusChange}>
                            <MenuItem value={0}>Prospect</MenuItem>
                            <MenuItem value={1}>Trial</MenuItem>
                            <MenuItem value={2}>Client</MenuItem>
                            <MenuItem value={3}>Opgeschort</MenuItem>
                            <MenuItem value={4}>Geen interesse</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        label="Adres"
                        fullWidth
                        onChange={this.handleAdresChange}
                        defaultValue={this.state.address}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={this.handleClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.handleSave}>
                        Opslaan
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

OrganisationEditDialog.propTypes = {
    organisation: PropTypes.object.isRequired,
    handleSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrganisationEditDialog);
