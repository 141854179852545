import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditContactDialog from './EditContactDialog';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        margin: `${theme.spacing.unit * 2}px 0`,
    },
});

class ContactCard extends Component {
    state = {
        anchorEl: null,
    };

    constructor(props) {
        super(props);
        this.editContactDialog = React.createRef();
    }

    handleOpen = event => {
        this.setState({ anchorEl: event.target });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleEdit = () => {
        this.handleClose();
        this.editContactDialog.current.open();
    };

    render() {
        const { classes, contact, organisation } = this.props;
        const anchorEl = this.state.anchorEl;
        const open = Boolean(anchorEl);

        contact.inServiceEmail = organisation.service_email_cc.includes(
            contact.email,
        );

        return (
            <div>
                <EditContactDialog
                    innerRef={this.editContactDialog}
                    handleSave={this.props.handleSave}
                    organisation={organisation}
                    contact={contact}
                />
                <Paper className={classes.root}>
                    <Grid container>
                        <Grid container alignItems="center">
                            <Grid item xs={3}>
                                <Typography variant="body2" noWrap>
                                    Naam:
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body1" noWrap>
                                    {contact.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography variant="body2" noWrap>
                                    E-mail adres:
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <a
                                    href={`mailto:${contact.email}`}
                                    className={classes.link}>
                                    <Typography
                                        color="primary"
                                        variant="body1"
                                        noWrap>
                                        {contact.email}
                                    </Typography>
                                </a>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={this.handleOpen}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={this.handleClose}
                                    PaperProps={{
                                        style: {
                                            width: 200,
                                        },
                                    }}>
                                    <MenuItem onClick={this.handleEdit}>
                                        <ListItemIcon className={classes.icon}>
                                            <EditIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            inset
                                            primary="Wijzigen"
                                        />
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={3}>
                                <Typography variant="body2" noWrap>
                                    Telefoon:
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body1" noWrap>
                                    {contact.phone}
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography variant="body2" noWrap>
                                    Servicemail:
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body1" noWrap>
                                    {contact.inServiceEmail ? 'Ja' : 'Nee'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={3}>
                                <Typography variant="body2" noWrap>
                                    Adverteerder:
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body1" noWrap>
                                    {contact.status ? 'Ja' : 'Nee'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

ContactCard.propTypes = {
    handleSave: PropTypes.func.isRequired,
    contact: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContactCard);
