import { FILTERS_SET } from '../actions/filter.action';
import moment from 'moment';

export function filters(
    state = { selectedManagers: [], selectedDate: new moment() },
    action,
) {
    switch (action.type) {
        case FILTERS_SET:
            return action.filters;
        default:
            return state;
    }
}
