import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import SelectManagers from '../components/FilterComponents/SelectManagers';
import DateFilter from '../components/FilterComponents/DateFilter';
import { connect } from 'react-redux';
import { setFilters } from '../actions/filter.action';

const styles = theme => ({
    gridContainer: {
        margin: `0 0 ${theme.spacing.unit * 2}px 0`,
    },
});

const mapDispatchToProps = dispatch => {
    return {
        handleSetFilter: filters => {
            dispatch(setFilters(filters));
        },
    };
};

const mapStateToProps = state => {
    return {
        filters: state.filters,
    };
};

class CalendarFilters extends Component {
    handleFiltersChanged = filters => {
        if (filters.selectedManagers) {
            // If we set a selectedManagers we change the filters with the new selectedManagers and remain the current selectedDate
            this.props.handleSetFilter({
                selectedManagers: filters.selectedManagers,
                selectedDate: this.props.filters.selectedDate,
            });
        } else {
            // If we set a selectedDate we change the filters with the new selectedDate and remain the current selectedManagers
            this.props.handleSetFilter({
                selectedManagers: this.props.filters.selectedManagers,
                selectedDate: filters.selectedDate,
            });
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <Typography variant="caption">Filters</Typography>
                <Grid
                    className={classes.gridContainer}
                    spacing={8}
                    justify="space-between"
                    container
                    alignItems="flex-end">
                    <Grid item sm={4} xs={12}>
                        <DateFilter
                            label="Vanaf datum"
                            defaultValue={this.props.filters.selectedDate}
                            handleFilterChanged={this.handleFiltersChanged}
                        />
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <SelectManagers
                            fullWidth
                            multiple={true}
                            defaultValue={this.props.filters.selectedManagers}
                            handleFilterChanged={this.handleFiltersChanged}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(CalendarFilters));
