const DUTCH_MONTHS = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
];
const DUTCH_DAYS = [
    'Zondag',
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
];

/**
 * Rewrite the calendar so that each day has a title and items are loaded under the title.
 * @param {Array} calendar
 */
export function parseCalendar(calendar) {
    if (calendar.length === 0) return calendar;

    // Rewrite each targetDate to a date object and cancel out the hours
    calendar.map(item => {
        item.targetDate = new Date(item.targetDate);
        item.targetDate.setHours(0, 0, 0, 0);
        return item.targetDate;
    });

    // Group the array by the targetDate property
    const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    let result = [];
    calendar = groupBy(calendar, 'targetDate');

    // For each sub array make a title object and place this in the result array. Place the sub array under the title object
    Object.keys(calendar).forEach(day => {
        const titleDate = new Date(day);
        const title = {
            title: `${titleDate.getDate()} ${
                DUTCH_MONTHS[titleDate.getMonth()]
            } - ${DUTCH_DAYS[titleDate.getDay()]}`,
        };
        result = [...result, title, ...calendar[day]];
    });

    return result;
}
