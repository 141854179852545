import {
    SET_ORGANISATION,
    RESET_ORGANISATION,
    EDIT_CONTACT_ORGANISATION,
    ADD_CONTACT_ORGANISATION,
} from '../actions/organisation.action';
import {
    EDIT_CALENDAR_ORGANISATION,
    DELETE_CALENDAR_ITEM_ORGANISATION,
    ADD_CALENDAR_ITEM_ORGANISATION,
} from '../actions/calendar.action';
import {
    DELETE_NOTE_ITEM_ORGANISATION,
    EDIT_NOTE_ITEM_ORGANISATION,
    ADD_NOTE_ITEM_ORGANISATION,
} from '../actions/notes.action';

export function organisation(
    state = { contacts: [], calendar: [], notes: [] },
    action,
) {
    switch (action.type) {
        case SET_ORGANISATION:
            return action.organisation;
        case EDIT_CALENDAR_ORGANISATION:
            let calendar = state.calendar.map(item => {
                if (item.id !== action.item.id) return item;
                return {
                    ...item,
                    ...action.item,
                };
            });
            return {
                ...state,
                calendar: calendar,
            };
        case DELETE_CALENDAR_ITEM_ORGANISATION:
            return {
                ...state,
                calendar: state.calendar.filter(
                    item => item.id !== action.itemId,
                ),
            };
        case DELETE_NOTE_ITEM_ORGANISATION:
            return {
                ...state,
                notes: state.notes.filter(item => item.id !== action.itemId),
            };
        case EDIT_NOTE_ITEM_ORGANISATION:
            let notes = state.notes.map(item => {
                if (item.id !== action.item.id) return item;
                return {
                    ...item,
                    ...action.item,
                };
            });
            return {
                ...state,
                notes: notes,
            };
        case ADD_CALENDAR_ITEM_ORGANISATION:
            return {
                ...state,
                calendar: [...state.calendar, action.item],
            };
        case ADD_NOTE_ITEM_ORGANISATION:
            return {
                ...state,
                notes: [...state.notes, action.item],
            };
        case RESET_ORGANISATION:
            if (state.id !== action.orgId) return {};
            return state;
        case EDIT_CONTACT_ORGANISATION:
            let contacts = state.contacts.map(contact => {
                if (contact.id !== action.contact.id) return contact;
                return {
                    ...contact,
                    ...action.contact,
                };
            });

            return {
                ...state,
                service_email_cc: action.organisation.service_email_cc,
                contacts: contacts,
            };
        case ADD_CONTACT_ORGANISATION:
            return {
                ...state,
                contacts: [...state.contacts, action.contact],
            };
        default:
            return state;
    }
}
