import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import EventCard from '../components/EventCard';
import {
    getCalendarItems,
    deleteCalendarItem,
    editCalendarItem,
} from '../actions/calendar.action';
import Typography from '@material-ui/core/Typography';
import CalendarFiltersContainer from './CalendarFiltersContainer';
import { setFilters } from '../actions/filter.action';
import { history } from '../routes/Routes';
import ScrollToTop from '../components/ScrollToTop';
import InfiniteScrollContainer from './InfiniteScrollContainer';

const styles = theme => ({
    title: {
        padding: `${theme.spacing.unit * 2}px 0`,
    },
});

const mapDispatchToProps = dispatch => {
    return {
        getCalendar: (managerIds, startDate, pageToLoad, callback) => {
            dispatch(
                getCalendarItems(managerIds, startDate, pageToLoad, callback),
            );
        },
        deleteCalendarItem: itemId => {
            dispatch(deleteCalendarItem(itemId));
        },
        editCalendarItem: (itemId, oldValues, newValues) => {
            dispatch(editCalendarItem(itemId, oldValues, newValues));
        },
        setFilters: (selectedManagers, selectedDate) => {
            dispatch(
                setFilters({
                    selectedManagers: selectedManagers,
                    selectedDate: selectedDate,
                }),
            );
        },
    };
};

const mapStateToProps = state => {
    return {
        filters: state.filters,
    };
};

class CalendarContainer extends Component {
    getCalendar(prevProps) {
        if (!prevProps) return;

        const oldFilters = prevProps.filters;
        const newFilters = this.props.filters;

        if (
            oldFilters.selectedManagers.length !== 0 &&
            (oldFilters.selectedManagers.length !==
                newFilters.selectedManagers ||
                oldFilters.selectedDate !== newFilters.selectedDate)
        ) {
            const { selectedManagers, selectedDate } = newFilters;
            this.props.getCalendar(selectedManagers, selectedDate, 0);
        }
    }

    componentDidUpdate(prevProps) {
        this.getCalendar(prevProps);
    }

    componentDidMount() {
        if (!this.props.filters) return;

        this.getCalendar(this.props);
    }

    handleDeleteCalendarItem = itemId => {
        this.props.deleteCalendarItem(itemId);
    };

    handleClickCalendarItem = itemId => {
        history.push(`/crm/organisation/${itemId}`);
    };

    handleEditCalendarItem = (itemId, oldValues, newValues) => {
        this.props.editCalendarItem(itemId, oldValues, newValues);
    };

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <Typography className={classes.title} variant="display1">
                    Agenda
                </Typography>

                <CalendarFiltersContainer
                    handleFiltersChanged={this.handleFiltersChanged}
                />

                <InfiniteScrollContainer treshhold={250}>
                    <EventCard
                        style={{ margin: `16px 0` }}
                        handleClick={this.handleClickCalendarItem}
                        handleDelete={this.handleDeleteCalendarItem}
                        handleEdit={this.handleEditCalendarItem}
                    />
                </InfiniteScrollContainer>

                <ScrollToTop />
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(CalendarContainer));
