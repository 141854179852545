import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

const styles = theme => ({
    textField: {
        width: '100%',
    },
    bootstrapRoot: {
        borderRadius: 3,
        padding: '8px 12px',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        color: 'rgba(255, 255, 255, 0.9)',
    },
    bootstrapInput: {
        transition: theme.transitions.create(['width']),
    },
});

const Search = ({ classes, inputRef, other }) => {
    return (
        <TextField
            className={classes.textField}
            placeholder="Zoek naar een naam..."
            type="text"
            color="inherit"
            InputProps={{
                disableUnderline: true,
                classes: {
                    root: classes.bootstrapRoot,
                    input: classes.bootstrapInput,
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                inputRef: inputRef,
            }}
            InputLabelProps={{
                shrink: true,
            }}
            {...other}
        />
    );
};

Search.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Search);
