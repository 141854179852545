import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import NotFound from '../containers/NotFound/NotFound';
import LoginContainer from '../containers/LoginContainer';
import { PrivateRoute } from './PrivateRoute';
import CrmContainer from '../containers/CrmContainer';
import ResetPasswordContainer from '../containers/ResetPasswordContainer';

export const history = createBrowserHistory();

export default () => (
    <Router history={history}>
        <Switch>
            <Route path="/" exact component={LoginContainer} />
            <PrivateRoute path="/crm" component={CrmContainer} />
            <Route
                path="/resetPassword/:token"
                component={ResetPasswordContainer}
            />
            <Route component={NotFound} />
        </Switch>
    </Router>
);
