import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    progress: {
        flexGrow: 1,
        width: '100%',
    },
});

class ConfirmDeleteDialog extends Component {
    state = {
        open: false,
        progress: 0,
    };

    open = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleButtonPress = () => {
        this.progressBarTimer = setInterval(() => {
            if (this.state.progress <= 100) {
                if (this.state.progress === 100) {
                    this.buttonPressConfirmTimeout = setTimeout(
                        this.buttonPressConfirmed,
                        200,
                    ); // Small delay for the animation to fullfill
                }
                this.setState({ progress: this.state.progress + 25 });
            }
        }, 200);
    };

    handleButtonRelease = () => {
        clearTimeout(this.buttonPressConfirmTimeout);
        clearInterval(this.progressBarTimer);
        this.setState({ progress: 0 });
    };

    buttonPressConfirmed = () => {
        this.handleClose();
        this.handleButtonRelease();
        this.props.handleConfirm();
    };

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className={classes.progress}>
                    <LinearProgress
                        variant="determinate"
                        value={this.state.progress}
                    />
                </div>

                <DialogTitle id="alert-dialog-title">
                    Weet je zeker dat je dit item wilt verwijderen?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Zodra dit item is verwijdert kan dit niet meer worden
                        terug gehaald. Houd de knop ingedrukt om het item te
                        verwijderen.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="inherit">
                        Cancel
                    </Button>
                    <Button
                        onTouchStart={this.handleButtonPress}
                        onTouchEnd={this.handleButtonRelease}
                        onMouseDown={this.handleButtonPress}
                        onMouseUp={this.handleButtonRelease}
                        color="primary"
                        autoFocus>
                        Verwijder
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmDeleteDialog.propTypes = {
    handleConfirm: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfirmDeleteDialog);
