import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const styles = theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
});

const mapStateToProps = state => {
    return {
        managers: state.managers,
        manager: state.manager,
    };
};

class SelectManagers extends Component {
    state = {
        open: false,
        value: [],
    };

    handleManagersChanged = event => {
        this.handleClose();

        let newValue = event ? event.target.value : this.state.value;
        if (Array.isArray(newValue) === false) newValue = [newValue];

        this.setState({ value: newValue }, () => {
            // // When the user selects empty in the select array we return the managerId of the loggedIn manager
            if (this.state.value.length === 0)
                this.setState({ value: [this.props.manager.id] });

            this.props.handleFilterChanged({
                selectedManagers: this.state.value,
            });
        });
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDelete = manager => {
        const newState = this.state.value.filter(val => val !== manager);
        this.setState({ value: newState }, () => {
            this.handleManagersChanged();
        });
    };

    /**
     * Set the default value of the selectedManagers state when props.manager is available
     * @param {*} props
     * @param {*} current_state
     */
    static getDerivedStateFromProps(props, current_state) {
        if (props.defaultValue && current_state.value.length === 0) {
            return { value: props.defaultValue };
        }
        if (props.manager.id && current_state.value.length === 0) {
            return { value: [props.manager.id] };
        }
        return current_state;
    }

    filterManager = (managerArr, manager) => {
        return managerArr.find(managerEl => managerEl.id === manager);
    };

    render() {
        const { classes, theme, managers } = this.props;

        return (
            <FormControl fullWidth={this.props.fullWidth}>
                <InputLabel htmlFor="select-multiple-chip">
                    Selecteer een manager
                </InputLabel>
                <Select
                    open={this.state.open}
                    onOpen={this.handleOpen}
                    onClose={this.handleClose}
                    fullWidth
                    multiple={this.props.multiple}
                    value={this.state.value}
                    onChange={this.handleManagersChanged}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className={classes.chips}>
                            {selected.map((manager, key) => {
                                const selectedManager = this.filterManager(
                                    managers,
                                    manager,
                                );

                                if (selectedManager) {
                                    return (
                                        <Chip
                                            key={manager}
                                            label={selectedManager.name}
                                            className={classes.chip}
                                            onDelete={() => {
                                                this.handleDelete(manager);
                                            }}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </div>
                    )}
                    MenuProps={MenuProps}>
                    {managers.map(manager => (
                        <MenuItem
                            key={manager.id}
                            value={manager.id}
                            style={{
                                fontWeight:
                                    this.state.value &&
                                    this.state.value.indexOf(manager.id) === -1
                                        ? theme.typography.fontWeightRegular
                                        : theme.typography.fontWeightMedium,
                            }}>
                            {manager.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}

SelectManagers.propTypes = {
    selectedManagers: PropTypes.array,
    multiple: PropTypes.bool.isRequired,
    handleFilterChanged: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    null,
)(withStyles(styles, { withTheme: true })(SelectManagers));
