import moment from 'moment';

export const FILTERS_SET = 'FILTERS_SET';
export function setFilters(filters) {
    const defaultValues = {
        selectedManagers: [],
        selectedDate: new moment(),
    };

    return {
        type: FILTERS_SET,
        filters: { ...defaultValues, ...filters },
    };
}
