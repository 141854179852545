import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getManagerInfo, getManagers } from '../actions/manager.action';
import { logout } from '../actions/login.action';
import Header from '../components/Header';
import CalendarContainer from './CalendarContainer';
import OrganisationContainer from './OrganisationContainer';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import JWTToken from '../helpers/jwtToken';
import AccountsContainer from './AccountsContainer';
import ErrorBoundary from '../ErrorBoundary';

const styles = theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(650 + theme.spacing.unit * 2 * 2)]: {
            width: 650,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

const mapDispatchToProps = dispatch => {
    return {
        setManager: manager => {
            dispatch(getManagerInfo(manager));
        },
        logout: () => {
            dispatch(logout());
        },
        getManagers: () => {
            dispatch(getManagers());
        },
    };
};

const mapStateToProps = state => {
    return {
        manager: state.manager,
    };
};

class CrmContainer extends Component {
    componentDidMount() {
        const jwt = JWTToken.getToken('refreshToken');
        const decodedJwt = JWTToken.decodeToken(jwt);
        this.props.setManager(decodedJwt.manager.id);
        this.props.getManagers();
    }

    render() {
        const { manager, classes, logout } = this.props;

        return (
            <ErrorBoundary>
                <Header manager={manager} handleLogout={logout} />
                <Grid container className={classes.layout} spacing={16}>
                    <Grid item xs>
                        <Switch>
                            <Route
                                path="/crm/calendar"
                                component={CalendarContainer}
                            />
                            <Route
                                path="/crm/organisation/:orgId"
                                component={OrganisationContainer}
                            />
                            <Route
                                path="/crm/accounts"
                                component={AccountsContainer}
                            />
                            <Redirect to="/crm/calendar" />
                        </Switch>
                    </Grid>
                </Grid>
            </ErrorBoundary>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(CrmContainer));
