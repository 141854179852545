export default class JWTToken {
    /**
     * Check if a specific token in localStorage is expired
     * @param {String} tokenType This can either be 'refreshToken' or 'accessToken'
     */
    static checkTokenIsExpired(tokenType) {
        const tokens = getTokens();
        if (!tokens || !tokens[tokenType]) return true;

        const decoded = this.decodeToken(tokens[tokenType]);
        const currentSeconds = new Date().getTime() / 1000;
        return decoded.exp < currentSeconds;
    }

    static decodeToken(encodedToken) {
        if (encodedToken === false) return;
        const middleSectionUrl = encodedToken.split('.')[1];
        const base64 = middleSectionUrl.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    static getToken(tokenType) {
        const tokens = getTokens();
        if (!tokens && !tokens[tokenType]) return false;
        return tokens[tokenType];
    }
}

function getTokens() {
    if (!localStorage.getItem('tokens')) return false;
    return JSON.parse(localStorage.getItem('tokens'));
}
