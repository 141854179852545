import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const CARD_AMOUNT = 4;

const styles = theme => ({
    margin: {
        margin: `${theme.spacing.unit * 2}px 0`,
    },
});

class LoadMoreContainer extends Component {
    constructor(props) {
        super(props);
        this.items = this.props.items;
    }

    state = {
        limit: CARD_AMOUNT,
    };

    handleLoadMore = () => {
        this.setState({ limit: this.state.limit + CARD_AMOUNT });
    };

    componentWillReceiveProps(nextProps) {
        this.items = nextProps.items;
    }

    /**
     * Check if the items array has more items so we can do another load more
     */
    hasMore = () => {
        return typeof this.items[this.state.limit] !== 'undefined';
    };

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                {this.items.slice(0, this.state.limit).map(item => {
                    return (
                        <div className={classes.margin} key={item.id}>
                            {React.cloneElement(this.props.children, {
                                item: item,
                            })}
                        </div>
                    );
                })}

                {this.hasMore() && (
                    <Grid container justify="center">
                        <Grid item>
                            <Button onClick={this.handleLoadMore}>
                                Laad meer
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Fragment>
        );
    }
}

LoadMoreContainer.propTypes = {
    items: PropTypes.array.isRequired,
};

export default withStyles(styles, { withTheme: true })(LoadMoreContainer);
