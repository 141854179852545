import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getOrganisation } from '../actions/organisation.action';
import Typography from '@material-ui/core/Typography';
import OrganisationDetails from '../components/OrganisationComponents/OrganisationDetails';
import OrganisationContacts from '../components/OrganisationComponents/OrganisationContacts';
import OrganisationAgenda from '../components/OrganisationComponents/OrganisationAgenda';
import OrganisationNotes from '../components/OrganisationComponents/OrganisationNotes';
import {
    editCalendarItem,
    deleteCalendarItem,
    addCalendarItem,
} from '../actions/calendar.action';
import {
    editNoteItem,
    deleteNoteItem,
    addNoteItem,
} from '../actions/notes.action';
import { editOrganisation } from '../actions/organisation.action';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import NoteIcon from '@material-ui/icons/Note';
import EditEventDialog from '../components/EditEventDialog';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { history } from '../routes/Routes';
import { editContactOfOrganisation } from '../actions/organisation.action';
import { addContactToOrganisation } from '../actions/organisation.action';
import EditContactDialog from '../components/EditContactDialog';

const mapDispatchToProps = dispatch => {
    return {
        getOrganisation: orgId => {
            dispatch(getOrganisation(orgId));
        },
        editCalendarItem: (itemId, oldValues, newValues) => {
            dispatch(editCalendarItem(itemId, oldValues, newValues));
        },
        deleteCalendarItem: itemId => {
            dispatch(deleteCalendarItem(itemId));
        },
        editNoteItem: (itemId, oldValues, newValues) => {
            dispatch(editNoteItem(itemId, oldValues, newValues));
        },
        deleteNoteItem: itemId => {
            dispatch(deleteNoteItem(itemId));
        },
        addCalendarItem: calendarItem => {
            dispatch(addCalendarItem(calendarItem));
        },
        addNoteItem: noteItem => {
            dispatch(addNoteItem(noteItem));
        },
        editOrganisation: (oldValues, newValues) => {
            dispatch(editOrganisation(oldValues, newValues));
        },
        handleEditContact: (oldValues, newValues) => {
            dispatch(editContactOfOrganisation(oldValues, newValues));
        },
        handleAddContactToOrganisation: contact => {
            dispatch(addContactToOrganisation(contact));
        },
    };
};

const mapStateToProps = state => {
    return {
        organisation: state.organisation,
        managers: state.managers,
        manager: state.manager,
    };
};

const styles = theme => ({
    title: {
        padding: `${theme.spacing.unit * 4}px 0`,
    },
    card: {
        margin: `${theme.spacing.unit * 2}px 0`,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
    },
    loader: {
        margin: theme.spacing.unit * 2,
        display: 'block',
        align: 'center',
    },
});

class OrganisationContainer extends Component {
    state = {
        open: false,
        fabAnchorEl: null,
        creatingNote: false,
    };

    constructor(props) {
        super(props);
        this.editEventDialog = React.createRef();
        this.editContactDialog = React.createRef();
    }

    componentDidMount() {
        const orgId = this.props.match.params.orgId;
        this.props.getOrganisation(orgId);
    }

    handleEditCalendarItem = (itemId, oldValues, newValues) => {
        this.props.editCalendarItem(itemId, oldValues, newValues);
    };

    handleDeleteCalendarItem = itemId => {
        this.props.deleteCalendarItem(itemId);
    };

    handleEditNoteItem = (itemId, oldValues, newValues) => {
        this.props.editNoteItem(itemId, oldValues, newValues);
    };

    handleDeleteNoteItem = itemId => {
        this.props.deleteNoteItem(itemId);
    };

    handleFabOpen = event => {
        this.setState({
            fabAnchorEl: event.currentTarget,
        });
    };

    handleGoBack = () => {
        history.goBack();
    };

    handleFabClose = () => {
        this.setState({ fabAnchorEl: null });
    };

    handleAddCalendarItem = () => {
        this.setState({ creatingNote: false }, () => {
            this.editEventDialog.current.open();
            this.handleFabClose();
        });
    };

    handleAddNote = () => {
        this.setState({ creatingNote: true }, () => {
            this.editEventDialog.current.open();
            this.handleFabClose();
        });
    };

    handleAddContact = () => {
        this.editContactDialog.current.open();
        this.handleFabClose();
    };

    handleSaveEvent = (selectedManagers, targetDate, message) => {
        const item = {
            orgId: this.props.organisation.id,
            managerId: selectedManagers[0],
            message: message,
        };
        if (this.state.creatingNote === false) {
            item.targetDate = targetDate;
            return this.props.addCalendarItem(item);
        }

        return this.props.addNoteItem(item);
    };

    handleSaveContact = (oldValue, newValue) => {
        this.props.handleAddContactToOrganisation(newValue);
    };

    render() {
        const { classes, organisation, managers } = this.props;

        return (
            <Fragment>
                {organisation.id ? (
                    <Fragment>
                        <EditEventDialog
                            innerRef={this.editEventDialog}
                            isNote={this.state.creatingNote}
                            isNew={true}
                            loggedInManager={this.props.manager}
                            handleSave={this.handleSaveEvent}
                        />
                        <EditContactDialog
                            innerRef={this.editContactDialog}
                            organisation={organisation}
                            isNew={true}
                            handleSave={this.handleSaveContact}
                        />
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center">
                            <Grid item xs={1}>
                                <IconButton
                                    onClick={this.handleGoBack}
                                    aria-label="Terug">
                                    <ArrowBack />
                                </IconButton>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography
                                    className={classes.title}
                                    variant="title">
                                    {organisation.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <div>
                            <OrganisationDetails
                                organisation={organisation}
                                managers={managers}
                                handleEdit={this.props.editOrganisation}
                            />
                        </div>
                        <div className={classes.card}>
                            <OrganisationContacts
                                contacts={organisation.contacts}
                                organisation={organisation}
                                handleAdd={this.handleAddContact}
                                handleEdit={this.props.handleEditContact}
                            />
                        </div>
                        <div className={classes.card}>
                            <OrganisationAgenda
                                calendarItems={organisation.calendar}
                                handleAddCalendarItem={
                                    this.handleAddCalendarItem
                                }
                                handleEditCalendarItem={
                                    this.handleEditCalendarItem
                                }
                                handleDeleteCalendarItem={
                                    this.handleDeleteCalendarItem
                                }
                            />
                        </div>
                        <div className={classes.card}>
                            <OrganisationNotes
                                notes={organisation.notes}
                                handleAddNoteItem={this.handleAddNote}
                                handleEditNoteItem={this.handleEditNoteItem}
                                handleDeleteNoteItem={this.handleDeleteNoteItem}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <Grid container justify="center" key={0}>
                        <Grid item>
                            <CircularProgress className={classes.loader} />
                        </Grid>
                    </Grid>
                )}

                <Tooltip
                    title="Voeg contact, agenda punt of notitie toe"
                    placement="left">
                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={classes.fab}
                        onClick={this.handleFabOpen}>
                        <AddIcon />
                    </Button>
                </Tooltip>

                <Menu
                    id="simple-menu"
                    anchorEl={this.state.fabAnchorEl}
                    open={Boolean(this.state.fabAnchorEl)}
                    onClose={this.handleFabClose}>
                    <MenuItem onClick={this.handleAddContact}>
                        <ListItemIcon className={classes.icon}>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText inset primary="Contact toevoegen" />
                    </MenuItem>
                    <MenuItem onClick={this.handleAddCalendarItem}>
                        <ListItemIcon className={classes.icon}>
                            <CalendarTodayIcon />
                        </ListItemIcon>
                        <ListItemText inset primary="Agendapunt toevoegen" />
                    </MenuItem>
                    <MenuItem onClick={this.handleAddNote}>
                        <ListItemIcon className={classes.icon}>
                            <NoteIcon />
                        </ListItemIcon>
                        <ListItemText inset primary="Notitie toevoegen" />
                    </MenuItem>
                </Menu>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(OrganisationContainer));
