import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginForm from '../components/LoginForm';
import { signIn } from '../actions/login.action';
import { history } from '../routes/Routes';
import JWTToken from '../helpers/jwtToken';

const mapDispatchToProps = dispatch => {
    return {
        loginSubmit: (email, password) => {
            dispatch(signIn(email, password));
        },
    };
};

const mapStateToProps = state => {
    return {
        signInState: state.signInUIState,
    };
};

const loginContainer = class LoginContainer extends Component {
    componentDidMount() {
        if (JWTToken.checkTokenIsExpired('refreshToken') === false) {
            // If the token is not expired we redirect to the calendar page
            history.push('/crm/calendar');
        }
    }

    loginSubmit = event => {
        event.preventDefault();

        const email = event.target.email.value;
        const password = event.target.password.value;
        this.props.loginSubmit(email, password);
    };
    render() {
        return (
            <LoginForm
                handleSubmit={this.loginSubmit}
                processing={this.props.signInState}
            />
        );
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(loginContainer);
