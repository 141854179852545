import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import NotificationImportant from '@material-ui/icons/NotificationImportant';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class OrganisationAgenda extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Paper className={classes.root}>
                <Grid container alignItems="center">
                    <Grid item xs={1}>
                        <NotificationImportant color="primary" />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography>
                            <i>{this.props.children}</i>
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles, { withTheme: true })(OrganisationAgenda);
