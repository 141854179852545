import ApiCall from '../helpers/ApiCall';
import { notify } from './notification.action';

export function getOrganisation(orgId) {
    return dispatch => {
        dispatch(resetOrganisation(orgId));

        let organisationStore = null;
        ApiCall.get(`${process.env.REACT_APP_API_URL}/organisation/${orgId}`)
            .then(organisation => {
                organisationStore = organisation;
                return ApiCall.get(
                    `${
                        process.env.REACT_APP_API_URL
                    }/organisation/${orgId}/contacts`,
                );
            })
            .then(contacts => {
                organisationStore.contacts = contacts;
                return ApiCall.get(
                    `${
                        process.env.REACT_APP_API_URL
                    }/organisation/${orgId}/calendar?limit=100`,
                );
            })
            .then(calendar => {
                organisationStore.calendar = calendar;
                return ApiCall.get(
                    `${
                        process.env.REACT_APP_API_URL
                    }/organisation/${orgId}/notes?limit=100`,
                );
            })
            .then(notes => {
                organisationStore.notes = notes;
                dispatch(setOrganisation(organisationStore));
            });
    };
}

export const SET_ORGANISATION = 'SET_ORGANISATION';
export function setOrganisation(organisation) {
    return {
        type: SET_ORGANISATION,
        organisation: organisation,
    };
}

export const EDIT_ORGANISATION = 'EDIT_ORGANISATION';
export function editOrganisation(oldValues, newValues) {
    return dispatch => {
        ApiCall.put(
            `${process.env.REACT_APP_API_URL}/organisation/${oldValues.id}`,
            newValues,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message:
                            'Het is niet gelukt een organisatie te wijzigen.',
                    }),
                );
            }
            dispatch(
                notify({
                    level: 'success',
                    message: 'De organisatie is gewijzigd',
                }),
            );

            return dispatch(getOrganisation(oldValues.id));
        });
    };
}

export const RESET_ORGANISATION = 'RESET_ORGANISATION';
export function resetOrganisation(orgId) {
    return {
        type: RESET_ORGANISATION,
        orgId: orgId,
    };
}

export const EDIT_CONTACT_ORGANISATION = 'EDIT_CONTACT_ORGANISATION';
export function editContactOfOrganisation(oldValues, newValues) {
    return dispatch => {
        const body = { ...oldValues, ...newValues };
        ApiCall.put(
            `${process.env.REACT_APP_API_URL}/contact/${body.id}`,
            body,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message: 'Het is niet gelukt het contact te wijzigen.',
                    }),
                );
            }
            dispatch(
                notify({
                    level: 'success',
                    message: 'Het contact is gewijzigd',
                }),
            );

            return dispatch({
                type: EDIT_CONTACT_ORGANISATION,
                contact: result.contact,
                organisation: result.organisation,
            });
        });
    };
}

export const ADD_CONTACT_ORGANISATION = 'ADD_CONTACT_ORGANISATION';
export function addContactToOrganisation(contact) {
    return dispatch => {
        ApiCall.post(
            `${process.env.REACT_APP_API_URL}/organisation/${
                contact.organisation_id
            }/contacts`,
            contact,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message:
                            'Het is niet gelukt om dit contact toe te voegen',
                    }),
                );
            }

            dispatch(
                notify({
                    level: 'success',
                    message: 'Het is contact is toegevoegd',
                }),
            );

            return dispatch({
                type: ADD_CONTACT_ORGANISATION,
                contact: result,
            });
        });
    };
}
