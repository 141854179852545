import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import EventCard from '../EventCard';
import AlertPaper from '../AlertPaper';
import LoadMoreContainer from '../../containers/LoadMoreContainer';
import { Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
    title: {
        margin: `${theme.spacing.unit * 2}px 0`
    }
})

class OrganisationNotes extends Component {
    handleDeleteNoteItem = (itemId) => {
        this.props.handleDeleteNoteItem(itemId);
    }

    handleEditNoteItem = (itemId, oldValues, newValues) => {
        this.props.handleEditNoteItem(itemId, oldValues, newValues);    
    }

    render() {
        const {classes, notes} = this.props;

        return (
            <div>
                <Grid container className={classes.title} alignItems="center">
                    <Grid item xs={11}>
                        <Typography variant="subheading">Notities</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={this.props.handleAddNoteItem}><AddIcon/></IconButton>
                    </Grid>
                </Grid>
                {notes.length === 0 ? (
                    <AlertPaper>Er zijn momenteel geen notities beschikbaar voor deze organisatie</AlertPaper>
                ) : (
                    <LoadMoreContainer items={notes.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).reverse()}>
                        <EventCard
                            noHover
                            showDate
                            handleDelete={this.handleDeleteNoteItem}
                            handleEdit={this.handleEditNoteItem}></EventCard>
                    </LoadMoreContainer>
                )}
            </div>
        )
    }
}

OrganisationNotes.propTypes = {
    notes: PropTypes.array.isRequired,
    handleAddNoteItem: PropTypes.func.isRequired,
    handleDeleteNoteItem: PropTypes.func.isRequired,
    handleEditNoteItem: PropTypes.func.isRequired
}

export default withStyles(styles, {withTheme: true})(OrganisationNotes)

