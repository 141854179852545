import React, { Component } from 'react';
import Search from '../components/Search';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import ApiCall from '../helpers/ApiCall';
import { history } from '../routes/Routes';
import { connect } from 'react-redux';
import { getOrganisation } from '../actions/organisation.action';

let selectedOrgId = 0;

const mapDispatchToProps = dispatch => {
    return {
        getOrganisation: orgId => {
            dispatch(getOrganisation(orgId));
        },
    };
};

const styles = theme => ({
    root: {
        height: 250,
        flexGrow: 1,
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

function renderInputComponent(inputProps) {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    return (
        <Search
            other={other}
            inputRef={node => {
                ref(node);
                inputRef(node);
            }}
        />
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }, props) {
    const matches = match(suggestion.n, query);
    const parts = parse(suggestion.n, matches);

    /**
     * If we click on one of the suggestion we go to their organisation route
     */
    const handleClick = () => {
        selectedOrgId = suggestion.org;
    };

    return (
        <MenuItem
            onClick={handleClick}
            selected={isHighlighted}
            component="div">
            <ListItemIcon>
                <Icon>{suggestion.icon}</Icon>
            </ListItemIcon>
            {parts.map((part, index) => {
                return part.highlight ? (
                    <strong
                        key={String(index)}
                        style={{ fontWeight: 500, whiteSpace: 'pre' }}>
                        {part.text}
                    </strong>
                ) : (
                    <span key={String(index)} style={{ whiteSpace: 'pre' }}>
                        {part.text}
                    </span>
                );
            })}
        </MenuItem>
    );
}

/**
 * Retrieves the suggestions matching the query of the user from the API
 * @param {String} value Search query filed by the user
 */
async function getSuggestions(value) {
    if (value.length > 2) {
        ApiCall.cancel(); // Cancel all previously requests
        let suggestionCategories = await ApiCall.get(
            `${process.env.REACT_APP_API_URL}/search/name?q=${value}`,
        );

        if (suggestionCategories) {
            suggestionCategories = reduceSuggestions(suggestionCategories);
            suggestionCategories = addIconsToSuggestions(suggestionCategories);

            return [
                ...suggestionCategories[0],
                ...suggestionCategories[1],
                ...suggestionCategories[2],
            ];
        }
    }
    return [];
}

/**
 * If the size of the total of the three arrays is bigger than 30 we reduce the array
 * So it will return 10 of each category for performance purposes
 * @param {Array} suggestionCategories Three arrays in an array of objects
 */
function reduceSuggestions(suggestionCategories) {
    // Check if the result is bigger than 30 items. If so we only compute the first 10 of each category for performance
    let result = [
        ...suggestionCategories[0],
        ...suggestionCategories[1],
        ...suggestionCategories[2],
    ];
    if (result.length > 30) {
        suggestionCategories[0] = suggestionCategories[0].slice(0, 10);
        suggestionCategories[1] = suggestionCategories[1].slice(0, 10);
        suggestionCategories[2] = suggestionCategories[2].slice(0, 10);
    }
    return suggestionCategories;
}

/**
 * Function to add an icon property to each object in the array
 * @param {Array} suggestionCategories Three arrays in an array of objects
 * @returns {Array} Array with three arrays of objects and the property icon added
 */
function addIconsToSuggestions(suggestionCategories) {
    const icons = ['person', 'business', 'build'];
    return suggestionCategories.map((suggestionCategorie, index) => {
        return suggestionCategorie.map(suggestion => {
            let obj = Object.assign({}, suggestion);
            obj.icon = icons[index];
            return obj;
        });
    });
}

function getSuggestionValue(suggestion) {
    return suggestion.n;
}

class SearchContainer extends Component {
    state = {
        single: '',
        suggestions: [],
    };

    handleSuggestionsFetchRequested = async ({ value }) => {
        this.setState({
            suggestions: await getSuggestions(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = name => (event, { newValue, method }) => {
        this.setState({
            [name]: newValue,
        });

        if (method === 'click') {
            history.push(`/crm/organisation/${selectedOrgId}`);
            this.props.getOrganisation(selectedOrgId);
        }
    };

    render() {
        const { classes } = this.props;

        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion,
        };
        return (
            <Autosuggest
                {...autosuggestProps}
                inputProps={{
                    classes,
                    value: this.state.single,
                    onChange: this.handleChange('single'),
                }}
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={options => (
                    <Paper {...options.containerProps} square>
                        {options.children}
                    </Paper>
                )}
                maxSearchResults={3}
            />
        );
    }
}

SearchContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(SearchContainer));
