import { NOTIFY, CLOSE_NOTIFICATION } from '../actions/notification.action';

export function notifications(state = [], action) {
    switch (action.type) {
        case NOTIFY:
            action.notification.id = state.length;
            return [action.notification, ...state];
        case CLOSE_NOTIFICATION:
            return state.filter(notification => notification.id !== action.id);
        default:
            return state;
    }
}
