import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import AlertPaper from '../AlertPaper';
import ContactCard from '../ContactCard';
import { Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
    title: {
        margin: `${theme.spacing.unit * 2}px 0`
    },
    link: {
        textDecoration: 'none'
    }
})

class OrganisationContacts extends Component {
    render() {
        const { classes, contacts, organisation } = this.props;

        return (
            <div>
                <Grid container alignItems="center" className={classes.title}>
                    <Grid item xs={11}>
                        <Typography variant="subheading">Contactpersonen</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={this.props.handleAdd}><AddIcon/></IconButton>
                    </Grid>
                </Grid>

                {contacts.length === 0 ? (
                    <AlertPaper>Er zijn momenteel geen contacten beschikbaar voor deze organisatie</AlertPaper>
                    ) : (
                        contacts.map(contact => {
                            return <ContactCard key={contact.id} contact={contact} organisation={organisation} handleSave={this.props.handleEdit}></ContactCard>
                    })
                )}
            </div>
        );
    }
}

OrganisationContacts.propTypes = {
    contacts: PropTypes.array.isRequired,
    organisation: PropTypes.object.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired
}

export default withStyles(styles, {withTheme: true})(OrganisationContacts)