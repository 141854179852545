import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import EditEventDialog from './EditEventDialog';
import moment from 'moment';
moment.locale('nl');

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        transition: 'box-shadow .2s',
    },
    hover: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        transition: 'box-shadow .2s',

        '&:hover': {
            'box-shadow': 'none',
            cursor: 'pointer',
            transition: 'box-shadow .2s',
        },

        '&:hover .title': {
            color: theme.palette.primary.dark,
        },
    },
});

class EventItem extends Component {
    constructor(props) {
        super(props);
        this.confirmDialog = new React.createRef();
        this.editDialog = new React.createRef();
    }
    state = {
        anchorEl: null,
    };

    handleMenuClick = event => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = event => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };

    handleDelete = event => {
        this.handleClose(event);
        this.confirmDialog.current.open();
    };

    handleConfirmDelete = () => {
        this.props.handleDelete(this.props.item.id);
    };

    handleItemClick = () => {
        if (this.props.handleClick)
            this.props.handleClick(this.props.item.orgId);
    };

    handleEdit = event => {
        this.handleClose(event);
        this.editDialog.current.open();
    };

    handleItemSave = (selectedManagers, targetDate, message) => {
        this.props.handleEdit(this.props.item.id, this.props.item, {
            managerId: selectedManagers[0],
            targetDate: targetDate,
            message: message,
        });
    };

    rewriteDate = date => {
        if (!date) date = this.props.item.createdAt; // For notes we don't have a target date so we use the createdAt date
        return new moment(date).format('dddd, D MMMM YYYY');
    };

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const { targetDate, message, Manager, Organisation } = this.props.item;
        const { classes, noHover, showDate } = this.props;

        return (
            <div style={this.props.style}>
                <ConfirmDeleteDialog
                    innerRef={this.confirmDialog}
                    handleConfirm={this.handleConfirmDelete}
                />
                <EditEventDialog
                    innerRef={this.editDialog}
                    event={this.props.item}
                    handleSave={this.handleItemSave}
                />

                <Paper
                    className={noHover ? classes.root : classes.hover}
                    onClick={this.handleItemClick}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography
                                className="title"
                                gutterBottom
                                dangerouslySetInnerHTML={{ __html: message }}
                            />
                            <Typography variant="caption">
                                <strong>
                                    {showDate
                                        ? this.rewriteDate(targetDate)
                                        : Organisation
                                            ? Organisation.name
                                            : 'Organisatie naam bestaat niet'}
                                </strong>{' '}
                                {Manager ? `- ${Manager.name}` : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                onClick={this.handleMenuClick}
                                aria-label="More"
                                aria-owns={open ? 'long-menu' : null}
                                aria-haspopup="true">
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={this.handleClose}
                                PaperProps={{
                                    style: {
                                        width: 200,
                                    },
                                }}>
                                <MenuItem onClick={this.handleEdit}>
                                    <ListItemIcon className={classes.icon}>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary="Wijzigen" />
                                </MenuItem>
                                <MenuItem onClick={this.handleDelete}>
                                    <ListItemIcon className={classes.icon}>
                                        <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary="Verwijder" />
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

EventItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        message: PropTypes.string.isRequired,
        Manager: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        Organisation: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
    }),
    handleDelete: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
};

export default withStyles(styles)(EventItem);
