import fetch from 'cross-fetch';
import { notify } from './notification.action';
import { history } from '../routes/Routes';

export const PROCESS_SIGN_IN = 'REQUEST_SIGN_IN';
function processingSignIn(state) {
    return {
        type: PROCESS_SIGN_IN,
        state: state,
    };
}

export const SIGN_IN = 'SIGN_IN';
export function signIn(email, password) {
    return dispatch => {
        dispatch(processingSignIn(true));

        return fetch(`${process.env.REACT_APP_API_URL}/auth`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })
            .then(
                response => response.json(),
                error => {
                    dispatch(
                        notify({
                            level: 'warning',
                            message: 'No internet connection',
                        }),
                    );
                },
            )
            .then(result => {
                dispatch(processingSignIn(false));

                if (result.error) {
                    return dispatch(
                        notify({
                            level: 'error',
                            message: result.error.message,
                        }),
                    );
                }

                localStorage.setItem('tokens', JSON.stringify(result)); // Place the tokens inside the localStorage of the user
                return history.push('/crm/calendar');
            });
    };
}

export function logout() {
    return dispatch => {
        localStorage.removeItem('tokens');
        return history.push('/');
    };
}
