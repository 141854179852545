import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Zoom from '@material-ui/core/Zoom';

const styles = theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class ScrollToTop extends Component {
    state = {
        show: false,
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const scrollY = window.scrollY;
        // Set the state of show if the scrollY is bigger than the window height
        this.setState({ show: scrollY > window.innerHeight });
    };

    handleClick = () => {
        window.scrollTo(0, 0);
    };

    render() {
        const { classes } = this.props;

        return (
            <Zoom in={this.state.show}>
                <Button
                    onClick={this.handleClick}
                    variant="fab"
                    className={classes.fab}
                    aria-label="Scroll to top">
                    <ArrowUpward />
                </Button>
            </Zoom>
        );
    }
}

export default withStyles(styles)(ScrollToTop);
