import { combineReducers } from 'redux';

import { signInUIState } from './login.reducer';
import { notifications } from './notification.reducer';
import { manager } from './manager.reducer';
import { managers } from './manager.reducer';
import { calendar } from './calendar.reducer';
import { organisation } from './organisation.reducer';
import { filters } from './filter.reducer';

const rootReducer = combineReducers({
    signInUIState,
    notifications,
    manager,
    managers,
    calendar,
    organisation,
    filters,
});

export default rootReducer;
