import { PROCESS_SIGN_IN } from '../actions/login.action';

export function signInUIState(state = false, action) {
    switch (action.type) {
        case PROCESS_SIGN_IN:
            return action.state;
        default:
            return state;
    }
}
