import JWTToken from './jwtToken';
import { history } from '../routes/Routes';
import Fetcher from './Fetch';

let abortController = new AbortController();
let abortSignal = abortController ? abortController.signal : null;

/**
 * This class handles all HTTP Requests to the server.
 * Every request made with this class can be considered as authenticated
 * Authentication is done with an short-live JWT access token and a long-live JWT refresh token
 */
export default class ApiCall {
    /**
     * Makes an authenticated HTTP GET request to a specific url
     * @param {String} url Url for the HTTP request
     * @returns {Promise} HTTP Request
     */
    static async get(url) {
        const token = await getAValidAccessToken();
        if (token === false) return;

        return generateRequest(url, token, 'get', abortSignal);
    }

    /**
     * Makes an authenticated HTTP POST request to a specific url
     * @param {String} url Url for the HTTP Request
     * @param {Object} body Body object of the HTTP Request
     * @returns {Promise} HTTP Request
     */
    static async post(url, body) {
        const token = await getAValidAccessToken();
        if (token === false) return;

        return generateRequest(url, token, 'post', abortSignal, body);
    }

    /**
     * Makes an authenticated HTTP PUT request to a specific url
     * @param {String} url Url for the HTTP Request
     * @param {Object} body Body object of the HTTP Request
     * @returns {Promise} HTTP Request
     */
    static async put(url, body) {
        const token = await getAValidAccessToken();
        if (token === false) return;

        return generateRequest(url, token, 'put', abortSignal, body);
    }

    /**
     * Makes an authenticated HTTP DELETE request to a specific url
     * @param {String} url Url for the HTTP Request
     * @param {Object} [body] Body object of the HTTP Request
     * @returns {Promise} HTTP Request
     */
    static async delete(url, body = {}) {
        const token = await getAValidAccessToken();
        if (token === false) return;

        return generateRequest(url, token, 'delete', abortSignal, body);
    }

    static cancel() {
        abortController.abort();
        abortController = new AbortController(); // New fresh instance for requests after the cancel call
        abortSignal = abortController ? abortController.signal : null;
    }
}

/**
 * We create a worker to do our HTTP Fetch so it won't decrease the performance of the main thread
 * @param {String} url String where the request should be fired to
 * @param {String} accessToken AccessToken to add as header in the request for authentication
 * @param {String} method Can either be: 'get', 'post', 'put', 'delete' HTTP Method
 * @param {Object} body If there is any body data it can be passed through here
 */
function generateRequest(url, accessToken, method, abortSignal, body = {}) {
    const request = {
        method: method,
        signal: abortSignal,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'x-access-token': accessToken,
        },
    };
    if ((method === 'post' || method === 'put') && Object.keys(body).length > 0)
        request.body = JSON.stringify(body); // Add the postData if it is filled and method is post

    return Fetcher.generateRequest(url, request);
}

/**
 * Retrieving a valid accessToken that can be used to generate new HTTP requests to the server
 * Checks if the accessToken in localStorage is still valid. If not it will generate a new one with the existing refreshToken
 * If the refreshToken is invalid we redirect the user to the login page to re-authenticate
 * @returns {String|boolean} accessToken or a false boolean if an error occured or the refreshToken is invalid
 */
function getAValidAccessToken() {
    // Check if the accessToken is valid. If so all good we return the accessToken
    if (JWTToken.checkTokenIsExpired('accessToken') === false)
        return JWTToken.getToken('accessToken');
    // The accesToken is invalid so we need to check the refreshToken to get a new one
    if (JWTToken.checkTokenIsExpired('refreshToken') === true) {
        // Check if the refreshToken is a valid one. If not we need to redirect to the login page
        // and return false
        history.push('/');
        return false;
    }

    // Get a new accessToken with the refreshToken
    return receiveNewAccessToken(JWTToken.getToken('refreshToken'))
        .then(response => {
            if (response.ok === false) return false; // If the response is not ok something went wrong server-side
            return response.json();
        })
        .then(result => {
            if (result === false) return false;
            // Set the new accessToken in the localStorage
            localStorage.setItem(
                'tokens',
                JSON.stringify({
                    refreshToken: JWTToken.getToken('refreshToken'),
                    accessToken: result.accessToken,
                }),
            );
            return result.accessToken;
        });
}

/**
 * Uses the refreshToken to receive a new accessToken on the server
 * @param {String} refreshToken JWT RefreshToken
 * @returns {Promise} Returns a promise with the request for the accessToken
 */
function receiveNewAccessToken(refreshToken) {
    return fetch(`${process.env.REACT_APP_API_URL}/auth/accessToken`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
            refreshToken: refreshToken,
        }),
    });
}
