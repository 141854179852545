import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import EventCard from '../EventCard';
import AlertPaper from '../AlertPaper';
import LoadMoreContainer from '../../containers/LoadMoreContainer';
import { Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
    title: {
        margin: `${theme.spacing.unit * 2}px 0`
    }
})

class OrganisationAgenda extends Component {
    handleDeleteCalendarItem = (itemId) => {
        this.props.handleDeleteCalendarItem(itemId);
    }

    handleEditCalendarItem = (itemId, oldValues, newValues) => {
        this.props.handleEditCalendarItem(itemId, oldValues, newValues);    
    }

    render() {
        const {classes, calendarItems} = this.props;
        
        return (
            <div>
                <Grid container className={classes.title} alignItems="center">
                    <Grid item xs={11}>
                        <Typography variant="subheading">Agenda</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={this.props.handleAddCalendarItem}><AddIcon/></IconButton>
                    </Grid>
                </Grid>
                {calendarItems.length === 0 ? (
                    <AlertPaper>Er zijn momenteel geen agenda items beschikbaar voor deze organisatie</AlertPaper>
                ) : (
                    <LoadMoreContainer items={calendarItems.sort((a, b) => new Date(a.targetDate) - new Date(b.targetDate))}>
                        <EventCard
                            noHover
                            showDate
                            handleDelete={this.handleDeleteCalendarItem}
                            handleEdit={this.handleEditCalendarItem}></EventCard>
                    </LoadMoreContainer>
                )}
            </div>
        )
    }
}

OrganisationAgenda.propTypes = {
    calendarItems: PropTypes.array.isRequired,
    handleAddCalendarItem: PropTypes.func.isRequired
}

export default withStyles(styles, {withTheme: true})(OrganisationAgenda)

