import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import ManagerCard from '../components/ManagerComponents/ManagerCard';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {
    deleteManager,
    resetPasswordManager,
    editManager,
    addManager,
} from '../actions/manager.action';
import EditManagerDialog from '../components/ManagerComponents/EditManagerDialog';

const styles = theme => ({
    title: {
        padding: `${theme.spacing.unit * 2}px 0`,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
    },
});

const mapDispatchToProps = dispatch => {
    return {
        handleManagerDelete: managerId => {
            dispatch(deleteManager(managerId));
        },
        handleResetPassword: email => {
            dispatch(resetPasswordManager(email));
        },
        handleManagerEdit: (oldValue, newValue) => {
            dispatch(editManager(oldValue, newValue));
        },
        handleAddManager: (name, email, siteId) => {
            dispatch(addManager(name, email, siteId));
        },
    };
};

const mapStateToProps = state => {
    return {
        manager: state.manager,
        managers: state.managers,
    };
};

class AccountsContainer extends Component {
    constructor(props) {
        super(props);
        this.addManagerDialog = React.createRef();
    }

    handleFabOpen = () => {
        this.addManagerDialog.current.open();
    };

    render() {
        const { classes, managers } = this.props;

        return (
            <Fragment>
                <Typography className={classes.title} variant="display1">
                    Accounts
                </Typography>
                <EditManagerDialog
                    innerRef={this.addManagerDialog}
                    isNew={true}
                    handleSave={this.props.handleAddManager}
                />

                {managers.map(manager => (
                    <ManagerCard
                        key={manager.id}
                        manager={manager}
                        handleDelete={this.props.handleManagerDelete}
                        handleEdit={this.props.handleManagerEdit}
                        handleResetPassword={this.props.handleResetPassword}
                    />
                ))}

                <Tooltip title="Voeg een account toe" placement="left">
                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={classes.fab}
                        onClick={this.handleFabOpen}>
                        <AddIcon />
                    </Button>
                </Tooltip>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AccountsContainer));
