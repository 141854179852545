import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import EditManagerDialog from './EditManagerDialog';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        margin: `${theme.spacing.unit * 2}px 0`
    }
})


class ManagerCard extends Component {
    state = {
        anchorEl: null
    }
    
    constructor(props) {
        super(props);
        this.confirmDeleteDialog = React.createRef();
        this.editDialog = React.createRef();
    }

    handleMenuClick = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };

    handleEdit = (name, email, siteId) => {
        this.props.handleEdit(this.props.manager, {
            name: name, 
            email: email,
            siteId: siteId
        });
    }

    handleResetPassword = (event) => {
        this.handleMenuClose(event);
        this.props.handleResetPassword(this.props.manager.email);
    }

    handleDelete = () => {
        this.props.handleDelete(this.props.manager.id);
    }

    openConfirmDelete = (event) => {
        this.handleMenuClose(event);
        this.confirmDeleteDialog.current.open();
    }

    openEdit = () => {
        this.setState({anchorEl: null});
        this.editDialog.current.open();
    }

    render() {
        const {classes, manager} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);

        return (
            <div>
                <ConfirmDeleteDialog innerRef={this.confirmDeleteDialog} handleConfirm={this.handleDelete}></ConfirmDeleteDialog>
                <EditManagerDialog 
                    innerRef={this.editDialog}
                    manager={manager} 
                    handleSave={this.handleEdit}></EditManagerDialog>
                <Paper classes={classes}>
                    <Grid container alignItems="center">
                        <Grid item xs={5}>
                            <strong>Naam: </strong>
                            {manager.name}
                        </Grid>
                        <Grid item xs={5}>
                            <strong>E-mail: </strong>
                            {manager.email}
                        </Grid>
                        <Grid item xs={2} align="right">
                            <IconButton 
                                onClick={this.handleMenuClick} 
                                aria-label="More"
                                aria-owns={open ? 'long-menu' : null}
                                aria-haspopup="true">
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={this.handleMenuClose}
                            >
                                <MenuItem onClick={this.openEdit}>
                                    <ListItemIcon className={classes.icon}>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary="Wijzigen" />
                                </MenuItem>
                                <MenuItem onClick={this.handleResetPassword}>
                                    <ListItemIcon className={classes.icon}>
                                        <LockIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary="Reset wachtwoord" />
                                </MenuItem>
                                <MenuItem onClick={this.openConfirmDelete}>
                                    <ListItemIcon className={classes.icon}>
                                        <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary="Verwijder" />
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }
}

ManagerCard.propTypes = {
    classes: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleResetPassword: PropTypes.func.isRequired
};

export default withStyles(styles)(ManagerCard);