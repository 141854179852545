import React, { Component } from 'react';

import './App.css';
import Routes from './routes/Routes';
import CssBaseline from '@material-ui/core/CssBaseline';
import NotificationContainer from './containers/NotificationContainer';

class App extends Component {
    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <Routes />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default App;
