import {
    SET_CALENDAR,
    DELETE_CALENDAR_ITEM,
    PUSH_CALENDAR,
    EDIT_CALENDAR_ITEM,
} from '../actions/calendar.action';

export function calendar(state = [], action) {
    switch (action.type) {
        case SET_CALENDAR:
            return action.calendar;
        case PUSH_CALENDAR:
            return [...state, ...action.calendar];
        case EDIT_CALENDAR_ITEM:
            return state.map(item => {
                if (item.id !== action.item.id) return item;
                return {
                    ...item,
                    ...action.item,
                };
            });
        case DELETE_CALENDAR_ITEM:
            return state.filter(item => item.id !== action.itemId);
        default:
            return state;
    }
}
