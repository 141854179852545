import {
    SET_MANAGER,
    SET_MANAGERS,
    DELETE_MANAGER,
    EDIT_MANAGER,
    ADD_MANAGER,
} from '../actions/manager.action';

/**
 * Reflects the manager that is currently logged In
 * @param {*} state
 * @param {*} action
 */
export function manager(state = {}, action) {
    switch (action.type) {
        case SET_MANAGER:
            return action.manager;
        default:
            return state;
    }
}

/**
 * An array with all the available managers in the CRM
 * @param {*} state
 * @param {*} action
 */
export function managers(state = [], action) {
    switch (action.type) {
        case SET_MANAGERS:
            return action.managers;
        case DELETE_MANAGER:
            return state.filter(manager => manager.id !== action.managerId);
        case EDIT_MANAGER:
            return state.map(item => {
                if (item.id !== action.item.id) return item;
                return {
                    ...item,
                    ...action.item,
                };
            });
        case ADD_MANAGER:
            return [...state, action.item];
        default:
            return state;
    }
}
