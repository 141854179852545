import React, { Component } from 'react';
import nlLocale from 'moment/locale/nl';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import DatePicker from 'material-ui-pickers/DatePicker';
import PropTypes from 'prop-types';
import moment from 'moment';
moment.locale('nl');

class DateFilter extends Component {
    state = {
        value: new moment()
    }

    componentDidMount() {
        if(this.props.defaultValue) {
            this.setState({value: new moment(this.props.defaultValue)})
        }
    }

    handleDateChange = date => {
        this.setState({ value: date }, () => {
            this.props.handleFilterChanged(({selectedDate: date}));
        });
    }

    render() {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale={nlLocale} moment={moment}>
                <DatePicker
                    fullWidth
                    label={this.props.label}
                    value={this.state.value}
                    onChange={this.handleDateChange}
                    showTodayButton
                    todayLabel="Vandaag"
                />
            </MuiPickersUtilsProvider>
        )
    }
}

DateFilter.propTypes = {
    label: PropTypes.string.isRequired,
    handleFilterChanged: PropTypes.func.isRequired
}

export default DateFilter;

