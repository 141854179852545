import ApiCall from '../helpers/ApiCall';
import { notify } from './notification.action';
import { history } from '../routes/Routes';
import { setFilters } from './filter.action';

/**
 * Action to retrieve the manager's information. So we can set the manager object in redux
 * @param {Number} managerId
 */
export function getManagerInfo(managerId) {
    return dispatch => {
        ApiCall.get(
            `${process.env.REACT_APP_API_URL}/manager/${managerId}`,
        ).then(manager => {
            dispatch(setManager(manager));
            dispatch(setFilters({ selectedManagers: [manager.id] }));
        });
    };
}

export function getManagers() {
    return dispatch => {
        ApiCall.get(`${process.env.REACT_APP_API_URL}/managers`).then(
            managers => {
                dispatch(setManagers(managers));
            },
        );
    };
}

export const SET_MANAGERS = 'SET_MANAGERS';
export function setManagers(managers) {
    return {
        type: SET_MANAGERS,
        managers: managers,
    };
}

export const SET_MANAGER = 'SET_MANAGER';
export function setManager(manager) {
    return {
        type: SET_MANAGER,
        manager: manager,
    };
}

export const DELETE_MANAGER = 'DELETE_MANAGER';
export function deleteManager(managerId) {
    return dispatch => {
        ApiCall.delete(
            `${process.env.REACT_APP_API_URL}/managers/${managerId}`,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message:
                            'Het is niet gelukt het account te verwijderen',
                    }),
                );
            }

            dispatch(
                notify({
                    level: 'success',
                    message: 'Account is succesvol verwijderd',
                }),
            );

            return dispatch({
                type: DELETE_MANAGER,
                managerId: managerId,
            });
        });
    };
}

export function resetPasswordManager(email) {
    return dispatch => {
        ApiCall.get(
            `${process.env.REACT_APP_API_URL}/managers/${email}/resetPassword`,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message:
                            'Het is niet gelukt het wachtwoord te resetten',
                    }),
                );
            }

            dispatch(
                notify({
                    level: 'success',
                    message:
                        'Het wachtwoord is gereset. Er is een mail gestuurd naar de manager.',
                }),
            );
        });
    };
}

export function submitNewPassord(token, password) {
    return dispatch => {
        localStorage.removeItem('tokens');

        const body = {
            token: token,
            password: password,
        };
        fetch(`${process.env.REACT_APP_API_URL}/manager/setPassword`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(body),
        })
            .then(result => result.json())
            .then(result => {
                if (result.error) {
                    return dispatch(
                        notify({
                            level: 'error',
                            message:
                                'De token is verlopen. Vraag opnieuw een verzoek aan.',
                        }),
                    );
                }

                dispatch(
                    notify({
                        level: 'success',
                        message:
                            'Het wachtwoord is gereset. Login met uw nieuwe wachtwoord.',
                    }),
                );

                history.push('/');
            });
    };
}

export const EDIT_MANAGER = 'EDIT_MANAGER';
export function editManager(oldValue, newValue) {
    return dispatch => {
        const body = { ...oldValue, ...newValue };

        ApiCall.put(
            `${process.env.REACT_APP_API_URL}/managers/${body.id}`,
            body,
        ).then(result => {
            if (result.error) {
                return dispatch(
                    notify({
                        level: 'error',
                        message: 'Het is niet gelukt de manager te wijzigen.',
                    }),
                );
            }

            dispatch(
                notify({
                    level: 'success',
                    message: 'De manager is succesvol gewijzigd.',
                }),
            );

            return dispatch({
                type: EDIT_MANAGER,
                item: result,
            });
        });
    };
}

export const ADD_MANAGER = 'ADD_MANAGER';
export function addManager(name, email, siteId) {
    return dispatch => {
        const body = {
            name: name,
            email: email,
            siteId: siteId,
        };

        ApiCall.post(`${process.env.REACT_APP_API_URL}/managers`, body).then(
            result => {
                if (result.error) {
                    return dispatch(
                        notify({
                            level: 'error',
                            message:
                                'Het is niet gelukt een manager toe te voegen.',
                        }),
                    );
                }
                dispatch(
                    notify({
                        level: 'success',
                        message:
                            'De manager is toegevoegd. Hij/zij ontvangt een email.',
                    }),
                );

                return dispatch({
                    type: ADD_MANAGER,
                    item: result,
                });
            },
        );
    };
}
