import React, { Component } from 'react';
import { connect } from 'react-redux';
import Notification from '../components/Notification';
import { closeNotification } from '../actions/notification.action';

const mapDispatchToProps = dispatch => {
    return {
        closeNotification: notification => {
            if (notification) dispatch(closeNotification(notification.id));
        },
    };
};

const mapStateToProps = (state, ownProps) => {
    return {
        notifications: state.notifications,
    };
};

const notifyContainer = class NotificationContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            notification: {},
        };
        this.queue = [];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notifications !== this.props.notifications) {
            this.queue = [...this.props.notifications];
            if (this.state.open === true) {
                this.setState({ open: false });
                this.props.closeNotification(this.queue[0]);
            }
            if (prevProps.notifications.length === 0) this.processQueue();
        }
    }

    processQueue = () => {
        if (this.queue.length > 0 && this.state.open === false) {
            this.setState({
                open: true,
                notification: this.queue[0],
            });
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
        this.props.closeNotification(this.queue[0]);
    };

    handleExited = () => {
        this.processQueue();
    };

    render() {
        const { id, level, message } = this.state.notification;

        return (
            <Notification
                id={id}
                level={level}
                message={message}
                open={this.state.open}
                handleClose={this.handleClose}
                handleExited={this.handleExited}
            />
        );
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(notifyContainer);
